<template>
  <footer class="footer fixed-footer">
    <span>This site was built using <a target="_blank" href="https://vuejs.org">Vue.js</a> and <a target="_blank" href="https://getbootstrap.com/">Bootstrap</a>. Code on <a target="_blank" href="https://github.com/obinnangini/bootstrap-styleguide">Github</a></span>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
};
</script>
